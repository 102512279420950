(function e(t,n,r){function s(o,u){if(!n[o]){if(!t[o]){var a=typeof require=="function"&&require;if(!u&&a)return a(o,!0);if(i)return i(o,!0);var f=new Error("Cannot find module '"+o+"'");throw f.code="MODULE_NOT_FOUND",f}var l=n[o]={exports:{}};t[o][0].call(l.exports,function(e){var n=t[o][1][e];return s(n?n:e)},l,l.exports,e,t,n,r)}return n[o].exports}var i=typeof require=="function"&&require;for(var o=0;o<r.length;o++)s(r[o]);return s})({1:[function(require,module,exports){
(function($) {
	"use strict";

	var helpers = require("./modules/helpers.js"),
		map = require("./modules/gmap.js");

	// ==============================
	// Scroll buttons
	// ==============================
	$(".go-to").click(function(e){
		e.preventDefault();
		var go = $(this).data("link") || $(this).attr("href");
		$("html, body").stop().animate({
			scrollTop: $(go).offset().top
		}, 700, "swing");
	});

	$("button.kp-back-to-top").click(function(){
		$("html, body").stop().animate({
			scrollTop: 0
		}, 700, "swing");
	});


	if($(".kp-acordeon").exists()){

		$(".kp-acordeon").each(function(){
			var $acordeon = $(this),
				$titles = $acordeon.find(".kp-acordeon__item-title");

			$titles.eq(0).next().slideDown(400);

			$titles.on("click", function(){
				var $clicked = $(this);

				$clicked.toggleClass("active");
				$clicked.next().slideToggle(400);
			});

		});

	}

	if($(".kp-gallery").exists()){

		$(".kp-gallery").each(function(){
			var $gallery = $(this),
				$image = $gallery.find(".kp-gallery__img"),
				$items = $gallery.find(".kp-gallery__dot"),
				$btn_next = $gallery.find(".kp-gallery__next"),
				$btn_prev = $gallery.find(".kp-gallery__prev"),
				active_index = 0,
				$first_item = $items.eq(active_index);

			$image.attr("src", $first_item.data("url"));
			$first_item.addClass("active");

			function loadImage(index){
				var $dot = $items.eq(index),
					img_src = $dot.data("url");

				$image.attr("src", img_src);
				$dot.addClass("active")
					.siblings()
					.removeClass("active");
			}

			$items.on("click", function(){
				active_index = $(this).index();
				loadImage(active_index);
			})

			$btn_next.on("click", function(){
				(active_index == $items.length-1) ? active_index=0 : active_index++;
				loadImage(active_index);
			})

			$btn_prev.on("click", function(){
				(active_index == 0) ? active_index=$items.length-1 : active_index--;
				loadImage(active_index);
			})

		});

	}


	// ==============================
	// Google map init
	// ==============================
	if($(".kp-map").exists()){
		var map_selector = ".kp-map",
			icon_template = ".kp-map__icon";

		map.init(map_selector,icon_template);
	}



	// ==============================
	// Preloader
	// ==============================
	$("#preloader").fadeOut(500);

})(jQuery);
},{"./modules/gmap.js":2,"./modules/helpers.js":3}],2:[function(require,module,exports){
var map_styles = [
		{
			"featureType": "water",
			"elementType": "geometry.fill",
			"stylers": [
			{ "color": "#d3d3d3" }
			]
		},{
			"featureType": "transit",
			"stylers": [
			{ "color": "#808080" },
			{ "visibility": "off" }
			]
		},{
			"featureType": "road.highway",
			"elementType": "geometry.stroke",
			"stylers": [
			{ "visibility": "on" },
			{ "color": "#b3b3b3" }
			]
		},{
			"featureType": "road.highway",
			"elementType": "geometry.fill",
			"stylers": [
			{ "color": "#ffffff" }
			]
		},{
			"featureType": "road.local",
			"elementType": "geometry.fill",
			"stylers": [
			{ "visibility": "on" },
			{ "color": "#ffffff" },
			{ "weight": 1.8 }
			]
		},{
			"featureType": "road.local",
			"elementType": "geometry.stroke",
			"stylers": [
			{ "color": "#d7d7d7" }
			]
		},{
			"featureType": "poi",
			"elementType": "geometry.fill",
			"stylers": [
			{ "visibility": "on" },
			{ "color": "#ebebeb" }
			]
		},{
			"featureType": "administrative",
			"elementType": "geometry",
			"stylers": [
			{ "color": "#a7a7a7" }
			]
		},{
			"featureType": "road.arterial",
			"elementType": "geometry.fill",
			"stylers": [
			{ "color": "#ffffff" }
			]
		},{
			"featureType": "road.arterial",
			"elementType": "geometry.fill",
			"stylers": [
			{ "color": "#ffffff" }
			]
		},{
			"featureType": "landscape",
			"elementType": "geometry.fill",
			"stylers": [
			{ "visibility": "on" },
			{ "color": "#efefef" }
			]
		},{
			"featureType": "road",
			"elementType": "labels.text.fill",
			"stylers": [
			{ "color": "#696969" }
			]
		},{
			"featureType": "administrative",
			"elementType": "labels.text.fill",
			"stylers": [
			{ "visibility": "on" },
			{ "color": "#737373" }
			]
		},{
			"featureType": "poi",
			"elementType": "labels.icon",
			"stylers": [
			{ "visibility": "off" }
			]
		},{
			"featureType": "poi",
			"elementType": "labels",
			"stylers": [
			{ "visibility": "off" }
			]
		},{
			"featureType": "road.arterial",
			"elementType": "geometry.stroke",
			"stylers": [
			{ "color": "#d6d6d6" }
			]
		},{
			"featureType": "road",
			"elementType": "labels.icon",
			"stylers": [
			{ "visibility": "off" }
			]
		},{
		},{
			"featureType": "poi",
			"elementType": "geometry.fill",
			"stylers": [
			{ "color": "#dadada" }
			]
		}
		];

var init = function (map_selector, icon_selector){
	/*
	*  new_map
	*  This function will render a Google Map onto the selected jQuery element
	*  @type	function
	*  @date	8/11/2013
	*  @since	4.3.0
	*  @param	$el (jQuery element)
	*  @return	n/a
	*/

	function new_map( $el, icon) {
		var $markers = $el.find('.marker'),
			args = {
				zoom		: 14,
				center		: new google.maps.LatLng(0, 0),
				mapTypeId	: google.maps.MapTypeId.ROADMAP,
				disableDoubleClickZoom: false,
				mapTypeControl: false,
				streetViewControl: false,
				draggable : true,
				overviewMapControl: false,
				overviewMapControlOptions: {
					opened: false,
				},
				styles: map_styles
			};

		// create map
		var map = new google.maps.Map( $el[0], args);

		// add a markers reference
		map.markers = [];

		// add markers
		$markers.each(function(){
			add_marker( $(this), map, icon);
		});

		// center map
		center_map( map );

		// return
		return map;
	}

	/*
	*  add_marker
	*  This function will add a marker to the selected Google Map
	*  @type	function
	*  @date	8/11/2013
	*  @since	4.3.0
	*  @param	$marker (jQuery element)
	*  @param	map (Google Map object)
	*  @return	n/a
	*/

	function add_marker( $marker, map, icon ) {
		// create marker
		var latlng = new google.maps.LatLng( $marker.attr('data-lat'), $marker.attr('data-lng') ),
			marker = new google.maps.Marker({
				icon: icon,
				position: latlng,
				map: map
			});

		// add to array
		map.markers.push( marker );

		// if marker contains HTML, add it to an infoWindow
		if( $marker.html() ) {
			// create info window
			var infowindow = new google.maps.InfoWindow({
				content	: $marker.html(),
				maxWidth : 200
			});

			// show info window when marker is clicked
			google.maps.event.addListener(marker, 'click', function() {
				infowindow.open( map, marker );

				// Track last opened window
				if (opened_infoWindow === null) {
					opened_infoWindow = infowindow;
				} else {
					opened_infoWindow.close();

					if (opened_infoWindow == infowindow) {
						opened_infoWindow = null;
					} else {
						opened_infoWindow = infowindow;
					}
				}

			});
		}

	}

	/*
	*  center_map
	*  This function will center the map, showing all markers attached to this map
	*  @type	function
	*  @date	8/11/2013
	*  @since	4.3.0
	*  @param	map (Google Map object)
	*  @return	n/a
	*/

	function center_map( map ) {
		var bounds = new google.maps.LatLngBounds();

		// loop through all markers and create bounds
		$.each( map.markers, function( i, marker ){
			var latlng = new google.maps.LatLng( marker.position.lat(), marker.position.lng() );

			bounds.extend( latlng );
		});

		// only 1 marker?
		if( map.markers.length == 1 ) {
			// set center of map
			map.setCenter( bounds.getCenter() );
			map.setZoom( 14 );
		} else {
			// fit to bounds
			map.fitBounds( bounds );
		}

	}

	/*
	*  document ready
	*  This function will render each map when the document is ready (page has loaded)
	*  @type	function
	*  @date	8/11/2013
	*  @since	5.0.0
	*  @param	n/a
	*  @return	n/a
	*/
	// global var
	var map = null,
		opened_infoWindow = null;

	$(map_selector).each(function(){
		var $single_map = $(this),
			marker_icon = $single_map.find(icon_selector).data("icon");

		map = new_map( $single_map, marker_icon );
	});
};


module.exports ={
	init : init
};
},{}],3:[function(require,module,exports){
// ==============================
// Existence checker
// ==============================
$.fn.exists = function(){
	return this.length > 0;
};


// ==============================
// Check browser scrollbar width
// ==============================
var getScrollbarWidth = function (){
	var style = {"max-height":"100px", "overflow":"scroll", "position":"absolute"},
		wrapper = $("<div id='scroll_bar_check_A'></div>").css(style),
		inner = $("<div id='scroll_bar_check_B'></div>"),
		stretcher = $("<img src='/assets/img/force-scroll.png'/>"),
		scrollBarWidth = 0;

	wrapper.append(stretcher).append(inner);
	$("body").append(wrapper);

	scrollBarWidth = wrapper.width()-inner.width();
	wrapper.remove();

	return scrollBarWidth;
};



// ==============================
// Check IE version
// ==============================
var ieVersion = function () {
	var ua = window.navigator.userAgent;

	var msie = ua.indexOf("MSIE ");
	if (msie > 0) {
		// IE 10 or older => return version number
		return parseInt(ua.substring(msie + 5, ua.indexOf(".", msie)), 10);
	}

	var trident = ua.indexOf("Trident/");
	if (trident > 0) {
		// IE 11 => return version number
		var rv = ua.indexOf("rv:");
		return parseInt(ua.substring(rv + 3, ua.indexOf(".", rv)), 10);
	}

	var edge = ua.indexOf("Edge/");
	if (edge > 0) {
		// Edge (IE 12+) => return version number
		return parseInt(ua.substring(edge + 5, ua.indexOf(".", edge)), 10);
	}

	// other browser
	return false;
};


var equalHeights = function() {
	var maxHeight = 0,
	$this = $(this);

	$this.each( function() {
		var height = $(this).innerHeight();

		if ( height > maxHeight ) { maxHeight = height; }
	});

	return $this.css('height', maxHeight);
};


var ajustHeights = function(selector){
	var elements = $(selector);

	$.fn.ajustHeight = equalHeights;
	elements.css({height:"auto"}).ajustHeight();
};

module.exports = {
	getScrollbarWidth : getScrollbarWidth,
	ieVersion : ieVersion,
	equalHeights : equalHeights,
	ajustHeights : ajustHeights
};
},{}]},{},[1]);
